const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

let crd;
let currentSong;
let running = false;
let audio: HTMLAudioElement;

const button = document.querySelector("button#start");
const debugContainer = document.querySelector("#debug-container");

const serverUrl = process.env.SERVER_URL;

button!.ariaDisabled = "disabled";
button!.setAttribute("disabled", "disabled");

function success(pos) {
  crd = pos.coords;
  button!.removeAttribute("disabled");

  console.log("Your current position is:");
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
  debugCoords(crd);
  // yay send this off
}

function debugCoords(crd) {
  if (!debugContainer) return;
  const debugLineElement = document.createElement("p");
  debugLineElement.textContent += `Latitude : ${crd.latitude}\n`;
  debugLineElement.textContent += `Latitude : ${crd.latitude}\n`;
  debugLineElement.textContent += `More or less ${crd.accuracy} meters.`;
  debugContainer.appendChild(debugLineElement);
}

function addDebugLine(text: string) {
  const debugLineElement = document.createElement("p");
  debugLineElement.textContent = text;
  debugContainer.appendChild(debugLineElement);
}

function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
  addDebugLine(`Error fetching GPS: ${err.code}: ${err.message}`);
}

navigator.geolocation.getCurrentPosition(success, error, options);

button?.addEventListener("click", async () => {
  if (running) {
    console.log("Aready runnonig!");
    return;
  }
  try {
    const res = await fetch(
      `${serverUrl}/currentSong?longitude=${crd.longitude}&latitude=${crd.latitude}`
    );

    console.log(res);

    if (res.status === 404) {
      addDebugLine(`Initial setup no song`);
      return;
    }

    const json = await res.json();
    console.log(json);
    if (json.trackUrl) {
      currentSong = json.trackUrl;
      addDebugLine(`initial track is: ${json.trackUrl}`);
      audio = new Audio(
        `${serverUrl}/stream?longitude=${crd.longitude}&latitude=${crd.latitude}`
      );

      await audio.play();
      audio.addEventListener("ended", () => {
        currentSong = null;
      });
    }
  } catch (err) {
    console.error(err);
  } finally {
    checkForNewGPS();
    running = true;
  }
});

// for now a shabby interval
function checkForNewGPS() {
  setInterval(async () => {
    try {
      navigator.geolocation.getCurrentPosition(success, error, options);

      const res = await fetch(
        `${serverUrl}/currentSong?longitude=${crd.longitude}&latitude=${crd.latitude}`
      );

      console.log(res);
      if (res.status === 404) {
        addDebugLine(`interval check - 404`);
        if (audio) {
          currentSong = null;
          await audio.pause();
        }
        return;
      }
      const json = await res.json();
      console.log(json);
      addDebugLine(`interval check - got a track: ${json.trackUrl}`);
      if (json.trackUrl && json.trackUrl !== currentSong) {
        addDebugLine(`interval ${json.trackUrl} - is a new track - switching!`);
        console.log("Trigger a new song!");
        await audio.pause();
        audio.src = `${serverUrl}/stream?longitude=${crd.longitude}&latitude=${crd.latitude}`;
        await audio.play();
        currentSong = json.trackUrl;
      } else {
        addDebugLine(`same song no action`);
        console.log("Same song no action");
      }
    } catch (err) {
      addDebugLine(`error occurred fetching new song! ${JSON.stringify(err)}`);
      console.error(err);
    }
  }, 10 * 1000);
}
